import React, { useState } from "react";
import { Router } from "@reach/router";
import {
  PrivacySection,
  QuoteLayout,
  Thankyou,
} from "../../components/utility/quote";
import { Product_Quote_Routes } from "../../data/Routes";
import CopierContext from "../../contexts/copierContext";

import firebase from "gatsby-plugin-firebase";

import Question1 from "./Question1";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Form from "./Form";
import { ServicesMenuSection } from "../../components/containers/ServicesMenuSection";
import { Footer } from "../../components/utility";
import GtagCode from "../../data/GtagCode";

const Quote = () => {
  const [que1, setQue1] = useState(null);
  const [que2, setQue2] = useState(null);
  const [que3, setQue3] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const addLeadToBackend = async (values) => {
    await fetch("/.netlify/functions/sendgrid", {
      method: "POST",
      body: JSON.stringify({
        email: "hitesh.patel@icthub.com.au",
        subject: "New lead recived!!",
        name: values.fullname,
      }),
    });
    var timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.firestore().collection("Copier_and_Printer").add({
      customerDetails: values,
      "What type of printing do you need?": que1,
      "What paper sizes do you need to print?": que2,
      "Do you wish to purchase or lease?": que3,
      Lead_Generated: timestamp,
    });
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
  };

  return (
    <QuoteLayout>
      <GtagCode />
      <CopierContext.Provider
        value={{
          que1,
          setQue1,
          que2,
          setQue2,
          que3,
          setQue3,
          formValues,
          setFormValues,
          addLeadToBackend,
        }}
      >
        <Router>
          <section path={Product_Quote_Routes.CopierandPrinter}>
            <Question1 title="Copier and Printer" path="/" />
            <Question2 title="Copier and Printer" path="/2" />
            <Question3 title="Copier and Printer" path="/3" />
            <Form title="Copier and Printer" path="/4" />
            <Thankyou title="Copier and Printer" path="/thankyou" />
          </section>
        </Router>
      </CopierContext.Provider>
      <PrivacySection />
      <ServicesMenuSection />
      <Footer />
    </QuoteLayout>
  );
};

export default Quote;

// https://digitalcomparison.us7.list-manage.com/subscribe/post?u=dc9903757f3dbf1204e277447&amp;id=1d3c3354a4
